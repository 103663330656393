// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  rootpath: 'https://otr-admin.digitalexamregistration.com/api/candidate' ,  
  // rootpath: 'https://jpsc.digitalexamregistration.com/api/candidate' ,  
  // rootpath: 'https://register-mock.digitalexamregistration.com/api/candidate' ,  
  // rootpath: 'http://27.0.58.76:6001/api/candidate' ,  
  //  rootpath: 'http://localhost:5001/api/candidate' ,  
  // rootpath: 'https://ccsc.digitalexamregistration.com/api/candidate' ,  

  
  // rootpath: 'https://register.digitalexamregistration.com/api/candidate'
};
 
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
